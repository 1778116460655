<template>
    <div id="blog">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <h1 class="wow fadeInUp">{{ title }}</h1>
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link
                                                :to="{ name: 'home' }">home</router-link></li>
                                        <li class="breadcrumb-item"><router-link
                                                :to="{ name: 'blog' }">blog</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">{{ strippedHtml(title)
                                            }}...</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Page Single Post Start -->
        <div class="page-single-post">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Post Featured Image Start -->
                        <div class="post-image">
                            <figure class="image-anime reveal">
                                <img :src="lgimage" alt="">
                            </figure>
                        </div>
                        <!-- Post Featured Image Start -->

                        <!-- Post Single Content Start -->
                        <div class="post-content">
                            <!-- Post Entry Start -->
                            <div class="post-entry">
                                <div v-html="body"></div>
                                <div class="text-black">Published on {{ shortNormalFormatDate(date) }}</div>
                            </div>
                            <!-- Post Entry End -->

                            <!-- Post Tag Links Start -->
                            <div class="post-tag-links">
                                <div class="row align-items-center">
                                    <div class="col-lg-8">
                                        <!-- Post Tags Start -->
                                        <div class="post-tags wow fadeInUp" data-wow-delay="0.5s"
                                            v-if="tags.length > 0">
                                            <span class="tag-links">
                                                Tags:
                                                <router-link v-for="(tag, i) in tags" :key="i"
                                                    :to="{ name: 'blogtags', params: { tag: tag } }">{{ tag
                                                    }}</router-link>
                                            </span>
                                        </div>
                                        <!-- Post Tags End -->
                                    </div>

                                    <div class="col-lg-4">
                                        <!-- Post Social Links Start -->
                                        <div class="post-social-sharing wow fadeInUp" data-wow-delay="0.5s">
                                            <ul>
                                                <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                                            class="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href="https://www.instagram.com/ingomu.coaching/"
                                                        target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                                                <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                                            class="fa-brands fa-youtube"></i></a></li>
                                                <li><a href="https://www.facebook.com/ingomu.coaching/"
                                                        target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href="https://www.pinterest.com/ingomu_coaching/"><i
                                                            class="fa-brands fa-pinterest"></i></a></li>
                                            </ul>
                                        </div>
                                        <!-- Post Social Links End -->
                                    </div>
                                </div>
                            </div>
                            <!-- Post Tag Links End -->
                        </div>
                        <!-- Post Single Content End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Single Post End -->

        <!-- Page Book Appointment Start -->
        <div class="page-book-appointment bg-section mb-60">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <!-- Book Appointment Content Start -->
                        <div class="book-appointment-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp" data-wow-delay="0.2s">Weekly coaching tips in your inbox</h3>
                                <h2 class="wow fadeInUp">ACTIONABLE TIPS<br><span>FOR A BETTER LIFE</span></h2>
                            </div>
                            <!-- Section Title Start -->
                        </div>
                        <!-- Book Appointment Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Book Appointment Form Start -->
                        <div class="appointment-form">
                            <p class="wow fadeInUp">By subscribing to our newsletter, you allow us to contact you at the
                                email address provided. You may unsubscribe at any time. Your information will never be
                                shared or sold.</p>

                            <!-- Appointment Form Start -->
                            <form id="newsletterForm" action="#" method="POST" data-toggle="validator"
                                class="wow fadeInUp" data-wow-delay="0.25s">
                                <div class="row">
                                    <div class="form-group col-md-12 mb-4">
                                        <input type="email" name="email" class="form-control" id="email"
                                            placeholder="Enter your email" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="col-md-12">
                                        <button type="submit" class="btn-default mb-25">Subscribe</button>
                                        <div id="msgSubmit" class="h3 hidden"></div>
                                    </div>
                                </div>
                            </form>
                            <!-- Appointment Form End -->
                        </div>
                        <!-- Book Appointment Form End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Book Appointment End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import formateDateMixin from "@/mixins/formatDate";
import { validator } from "../../../mixins/validator";

export default {
    name: "blogdetailv4",
    metaInfo() {
        return {
            title: this.title,
        }
    },
    components: {
        SiteHeader,
        SiteFooter
    },
    mixins: [formateDateMixin],
    data() {
        return {
            newsletterForm: {
                email: ""
            },
            name: '',
            title: '',
            body: '',
            lgimage: '',
            date: '',
            slug: '',
            category: '',
            tags: [],
        }
    },
    methods: {
        ...mapActions(["newsletterConsumer"]),
        submit(email) {
            var _this = this;
            _this
                .newsletterConsumer({
                    email: email
                })
        },
        fetchBlogItem() {
            this.$store.dispatch("getBlogItem", { slug: this.$route.params.slug });
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 3).join(" ")
        },
    },
    mounted() {
        let formattedStr = this.$route.params.slug.replace(/-/g, ' ');
        formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1)
        this.name = formattedStr
        this.$store.dispatch("getBlogItem", { slug: this.$route.params.slug });

        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        var $window = $(window);
        var $this = this;

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()

            validator((newsletterForm) => {
                let email = newsletterForm[0]["value"];
                $this.submit(email);
            })
        })
    },
    computed: {
        ...mapGetters(["blogItem"]),
    },
    watch: {
        blogItem(newValue) {
            newValue.forEach(element => {
                this.name = element.title
                this.title = element.title
                setTimeout(() => {
                    const info = { page_path: location.pathname, page_title: element.title, page_location: location.href }
                    this.$gtag.pageview(info)
                }, 1000)
                this.body = element.body
                this.date = element.date
                this.tags = element.tags
                this.slug = element.slug
                this.category = element.category
                if (element.lgimage != null && element.lgimage != "") {
                    this.lgimage = unescape(element.lgimage.substring(0, element.lgimage.indexOf("&token=")))
                } else if (element.xlimage != null && element.xlimage != "") {
                    this.lgimage = unescape(element.xlimage.substring(0, element.xlimage.indexOf("&token=")))
                } else if (element.mdimage != null && element.mdimage != "") {
                    this.lgimage = unescape(element.mdimage.substring(0, element.mdimage.indexOf("&token=")))
                } else if (element.smimage != null && element.smimage != "") {
                    this.lgimage = unescape(element.smimage.substring(0, element.smimage.indexOf("&token=")))
                }
                // if (element.lgimage != null && element.lgimage != "") {
                //     this.lgimage = element.lgimage
                // } else if (element.xlimage != null && element.xlimage != "") {
                //     this.lgimage = element.xlimage
                // } else if (element.mdimage != null && element.mdimage != "") {
                //     this.lgimage = element.mdimage
                // } else if (element.smimage != null && element.smimage != "") {
                //     this.lgimage = element.smimage
                // }
            })
        },
        '$route': {
            handler: 'fetchBlogItem',
            immediate: true
        }
    }

}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-blog.png?v1);
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-60 {
    margin-bottom: 60px;
}

.page-book-appointment {
    background: var(--white-color);
    padding: 100px 0;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-blog-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>